import { fetchNewRequests, fetchMyAppointmentsById, getAppointment } from '../Fetch/allFetches'
import { Auth } from 'aws-amplify';

/*
 * action types
 */

export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS'
export const LOGOUT_ERROR = 'LOGOUT_ERROR'

export const FETCHED_NEW_ERQUESTS_ERROR = 'FETCHED_NEW_ERQUESTS_ERROR'
export const FETCHED_NEW_ERQUESTS_SUCCESS = 'FETCHED_NEW_ERQUESTS_SUCCESS'

export const FETCHED_MY_APPTS_ERROR = 'FETCHED_MY_APPTS_ERROR'
export const FETCHED_MY_APPTS_SUCCESS = 'FETCHED_MY_APPTS_SUCCESS'

export const FETCHED_APPT_ERROR = 'FETCHED_APPT_ERROR'
export const FETCHED_APPT_SUCCESS = 'FETCHED_APPT_SUCCESS'

/*
 * login action creators
 */

export function loginSuccessful() {
  return {
    type: LOGIN_SUCCESS
  };
}

export function logoutSuccessful() {
  return {
    type: LOGOUT_SUCCESS
  };
}

export function logoutUser() {
  return (dispatch, getState) => {

    console.log(`logoutUser()`);

    return Auth.signOut()
      .then((response) => dispatch(loggedOut()))
      .catch((error) => dispatch(loggedOut(null, error)));
  };
}
const loggedOut = (data, error) => {
  if(error) {
    return {
      type: LOGOUT_ERROR
    }
  }

  return {
    type: LOGOUT_SUCCESS,
  }
}

/*
 * request action creators
 */
export function getNewRequestsByMetro(metroArea) {
  return (dispatch, getState) => {

    const lastFetchedTime = getState().lastFetched.newRequests;
    // console.log(`try to fetch new request: ${lastFetchedTime}`)

    /**
     * only execute fetch if the last fetch was more than 10 seconds ago
     */
    if(lastFetchedTime === undefined || Date.now() > (lastFetchedTime + 10000)) {
      // console.log(`fetching new requests in ${metroArea}`)
      return fetchNewRequests(metroArea)
        .then((response) => {
          // console.log(response);
          dispatch(fetchedNewRequests(response.data));
        })
        .catch((error) => {
          console.error(error);
          dispatch(fetchedNewRequests(null, error));
        });
    }
  };
}

const fetchedNewRequests = (data, error) => {
  if(error) {
    return {
      type: FETCHED_NEW_ERQUESTS_ERROR,
      payload: error
    }
  }

  return {
    type: FETCHED_NEW_ERQUESTS_SUCCESS,
    payload: data
  }
}

/*
 * appt action creators
 */
export function fetchMyAppointments() {
  return (dispatch, getState) => {

    const lastFetchedTime = getState().lastFetched.myAppointments;
    // console.log(`try to fetch my appointments: ${lastFetchedTime}`)

    /**
     * only execute fetch if the last fetch was more than 10 seconds ago
     */
    if(lastFetchedTime === undefined || Date.now() > (lastFetchedTime + 10000)) {
      // console.log(`fetching my appointments`)

      return fetchMyAppointmentsById()
        .then((response) => dispatch(fetchedMyAppointments(response.data)))
        .catch((error) => dispatch(fetchedMyAppointments(null, error)));
    }
  };
}

const fetchedMyAppointments = (data, error) => {
  if(error) {
    return {
      type: FETCHED_MY_APPTS_ERROR,
      payload: error
    }
  }

  return {
    type: FETCHED_MY_APPTS_SUCCESS,
    payload: data
  }
}

/**
 * 
 * @param {*} request 
 * 
 * if setAppointment http request fails, the caller needs to catch the error
 */
export function getAppointmentAndUpdate(requestId) {
  return (dispatch, getState) => {
    
    return getAppointment(requestId)
        .then((response) => dispatch(fetchedOneAppointment(response.data)))
        .catch((error) => dispatch(fetchedOneAppointment(null, error)))

    // return setAppointment(request)
    //   .then(() => {
    //     return getAppointment(request.requestId)
    //       .then((response) => dispatch(fetchedOneAppointment(response.data)))
    //       .catch((error) => dispatch(fetchedOneAppointment(null, error)))
    //   });
  };
}

const fetchedOneAppointment = (data, error) => {
  if(error) {
    return {
      type: FETCHED_APPT_ERROR,
      payload: error
    }
  }

  return {
    type: FETCHED_APPT_SUCCESS,
    payload: data
  }
}