import axios from 'axios';
import { Auth } from 'aws-amplify';
import config from '../../config';

export function fetchNewRequests(metroArea) {
  return GETwithIdToken(`${config.requestServiceUrl}/getUnsetRequests?metroArea=${metroArea}`);
}

export function claimRequests(requestId) {
  return PUTwithIdToken(`${config.requestServiceUrl}/claimRequest`, {
    requestId: requestId
  });
}

export function fetchMyAppointmentsById() {
  return GETwithIdToken(`${config.apptServiceUrl}/getAllAppts`);
}

export function setAppointment(request) {
  return PUTwithIdToken(`${config.requestServiceUrl}/setAppointment`, request);
}

export function getAppointment(requestId) {
  return GETwithIdToken(`${config.apptServiceUrl}/getAppt?requestId=${requestId}`);
}

export function editAppointment(request) {
  return PUTwithIdToken(`${config.apptServiceUrl}/editAppointment`, request);
}

export function completeAppointment(request) {
  return POSTwithIdToken(`${config.apptServiceUrl}/completeAppointment`, request);
}

export function calculatePrices(docType, metroArea, signatureCount, additionals) {
  let additionalsStr = '';

  if(additionals.length === 0) {
    additionalsStr = '%5B%5D';
  } 
  else {
    // convert array to string representation
    additionalsStr = '%5B';
    for(const a of additionals) {
      additionalsStr = additionalsStr + `%7B%22price%22%3A${a.price}%7D%2C`
    }
    // remove last ',' and add final ']'
    additionalsStr = additionalsStr.substring(0, additionalsStr.length - 3)
    additionalsStr = additionalsStr + '%5D';
  }
  const url = `${config.pricingServiceUrl}/calculatePrices?docType=${docType}&metroArea=${metroArea}&signatureCount=${signatureCount}&additionals=${additionalsStr}`;
  return axios.get(url, {
    headers: {
      'x-api-key': config.pricingServiceKey
    }
  });
}

const GETwithIdToken = (url) => {
  return Auth.currentSession()
    .then(cognitoUserSession => {
      return axios.get(url, {
        headers: {'Authorization': cognitoUserSession.getIdToken().getJwtToken()}
      })
    });
}

const PUTwithIdToken = (url, data) => {
  return Auth.currentSession()
    .then(cognitoUserSession => {
      return axios.put(url, data,
      {
        headers: {'Authorization': cognitoUserSession.getIdToken().getJwtToken()}
      })
    });
}

const POSTwithIdToken = (url, data) => {
  return Auth.currentSession()
    .then(cognitoUserSession => {
      return axios.post(url, data,
      {
        headers: {'Authorization': cognitoUserSession.getIdToken().getJwtToken()}
      })
    });
}