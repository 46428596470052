import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web
import { allReducers } from './Reducers/allReducers'

const persistConfig = {
  key: 'nmsupply',
  storage,
}

function configureStore(state = initState) {
  const persistedReducer = persistReducer(persistConfig, allReducers)
  const theStore = createStore(persistedReducer, state, applyMiddleware(thunk));
  return {
    store: theStore,
    persistor: persistStore(theStore)
  };
}

// function configureStore(state = initState) {
//   const theStore = createStore(allReducers,state);
//   return {
//     store: theStore,
//   };
// }

export default configureStore;

const initState = {
  isLoggedIn: false,
  allNewRequests: [],
  myAppointments: [],
  myCompletedAppointments: [],
  lastFetched: {}
};

/**
 * 
 * whenever a fetch requests shouldn't be duplicated or needs a buffer time
 *    input the last fetched time into ${lastFetched}
 *    subsequently, check this value before executing the next fetch
 */