import React, { Component } from 'react';
import { Drawer, Button, Menu } from 'antd';
// const SubMenu = Menu.SubMenu;
// const MenuItemGroup = Menu.ItemGroup;
import { connect } from 'react-redux';

class NMNavbar extends Component {

	state = {
    visible: false
  }

  showDrawer = () => {
    this.setState({
      visible: true,
    });
  };

  onClose = () => {
    this.setState({
      visible: false,
    });
  };

  render() {

    const rightMenu = (mode) => {
      return <RightMenu 
        mode={mode}
        isLoggedIn={this.props.isLoggedIn}
        myApptsRedirect={this.props.myApptsRedirect}
        // myPrefsRedirect={this.props.myPrefsRedirect}
        logoutFn={this.props.logoutFn}
        onClose={this.onClose.bind(this)}
         />;
    };

    return (
      <nav className="nm-nav-menu-bar">
        <div 
          className="nm-nav-logo"
          onClick={this.props.homeRedirect}
        >
          <span style={{ fontFamily: '"Arial Black", Gadget, sans-serif', fontSize: 'x-large', color: 'rgb(91, 18, 19)' }}>
              NM
          </span>
        </div>
        <div className="nm-nav-menu-container">
          <div className="nm-nav-right-menu">
            {rightMenu("horizontal")}
          </div>
          <Button className="nm-nav-bars-menu" type="primary" onClick={this.showDrawer}>
            <span className="nm-nav-bars-btn"></span>
          </Button>
          <Drawer
            title="Menu"
            placement="right"
            closable={false}
            onClose={this.onClose}
            visible={this.state.visible}
          >
            {rightMenu("inline")}
          </Drawer>
        </div>
      </nav>
    );
  }
}

class RightMenu extends Component {
  render() {
    return (
      <Menu 
        selectable={false}
        mode={this.props.mode}
        onClick={( item ) => {
          const { key } = item;
          
          switch(key) {
            case "myappts":    
              this.props.myApptsRedirect();
              break;  
            // case "prefs":
            //   this.props.myPrefsRedirect();
            //   break;
            case "logout":
              this.props.logoutFn();
              break;    
            default:
              return;
          }

          this.props.onClose();
        }}
      >
        {/* menu items when logged in */}
        {this.props.isLoggedIn &&
          <Menu.Item key="myappts">
            My Appointments
          </Menu.Item>
        }
        {/* {this.props.isLoggedIn &&
          <Menu.Item key="prefs">
            Preferences
          </Menu.Item>
        } */}
        {this.props.isLoggedIn &&
          <Menu.Item key="logout">
            Logout
          </Menu.Item>
        }

        {/* menu items when NOT logged in */}
        {/* {!this.props.isLoggedIn &&
          <Menu.Item key="signin">
            Sign In
          </Menu.Item>
        } */}
      </Menu>
    );
  }
}


const mapStateToProps = state => {
  return {
    isLoggedIn: state.isLoggedIn
  }
};

export default connect(mapStateToProps)(NMNavbar);