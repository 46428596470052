import React, { Component } from 'react';
import { Row, Col, Button, Modal, Spin } from 'antd';
import { Redirect } from "react-router-dom";
import { docTypeDisplay } from '../../Util/display'
import { claimRequests } from '../../State/Fetch/allFetches'

export class RequestTile extends Component {
	state = {
    showConfirm: false,
    redirectMyAppts: false,
    waitingToRedirect: false
  }

  showModal = () => {
    this.setState({
      showConfirm: true
    });
  };

  onCancel = () => {
    this.setState({
      showConfirm: false
    });
  };

  onYes = () => {
    claimRequests(this.props.request.requestId)
      .then((res) => {
        // console.log(res)
        this.setState({
          waitingToRedirect: true
        })
        setTimeout(() => {
          this.setState({
            showConfirm: false,
            redirectMyAppts: true
          });
        }, 1500)
      })
      .catch((error) => {
        // console.log(error)
        this.setState({
          showConfirm: false,
        });
        setTimeout(() => {
          alert(`Unfortunately this request has already been claimed.`);
        }, 300)
      })
  };

  render() {
    // redirect to My Appointments
    if(this.state.redirectMyAppts) {
      return <Redirect to='/myappointments' push={true}/>;
    }

    const { request } = this.props;

    return (
      <React.Fragment> 
        <div className="nm-request-tile-container">
          <Row>
            <Col span={12}>
              <div>
                <span style={{ fontSize: "large", fontWeight: "800",
                                letterSpacing: "0.2em", color: "#5B1213" }}>
                  {request.requestId}
                </span>
              </div>
            </Col>
            <Col span={12} style={{ textAlign: 'right' }} >
              {request.requestStatus === "CLAIMED" &&
                <Button 
                  type="primary" shape="round" icon="check" size="large" id="nm-request-tile-btn"
                  disabled={true}>
                  Claimed
                </Button>
              }
              {request.requestStatus === "NEW" &&
                <Button 
                  type="primary" shape="round" icon="check" size="large" id="nm-request-tile-btn"
                  onClick={this.showModal}>
                  Claim
                </Button>  
              }
            </Col>
          </Row>
          <Row>
            <Col span={12} style={{ textAlign: 'center' }}>
              <div>
                {request.firstName}  {/* {request.lastName} */}
              </div>
              <div>
                {/* {request.phoneNo} */}
              </div>
            </Col>
            <Col span={12}>
              <div>
                <span style={{ fontSize: "large", fontWeight: "500",
                                letterSpacing: "0.05em", color: "#5B1213" }}>
                  {docTypeDisplay(request.docType)}
                </span>
              </div>
              <div>
                <span style={{ fontWeight: "300",
                                letterSpacing: "0.05em", color: "#5B1213" }}>
                  {new Date(request.createdAt).toLocaleString()}
                </span>
              </div>
              {/* <div>
                1 - Copies
              </div>
              <div>
                <Checkbox disabled={true} checked={true}/> Has Physical Copy
              </div>
              <div>
                <Checkbox disabled={true} checked={false}/> Needs Delivery
              </div> */}
            </Col>
          </Row>
        </div>
        <Modal
          visible={this.state.showConfirm}
          title="Confirm Request Claim"
          onCancel={this.onCancel}
          footer={[
            <Button key="claimCancel" onClick={this.onCancel} disabled={this.state.waitingToRedirect}>
              Cancel
            </Button>,
            <Button key="claimYes" type="primary" onClick={this.onYes} disabled={this.state.waitingToRedirect}>
              Yes
            </Button>,
          ]}
        >
          <React.Fragment>
            <p> As the notary that claims this request, you will be responsible for setting up an appointment with the client and fulfilling any additional requests. </p>
            <p> Click Yes if you accept these responsibilities. </p>

            {this.state.waitingToRedirect && 
              <div style={{ textAlign: 'right'}}>
                <Spin/>
              </div>
            }
          </React.Fragment>
        </Modal>
      </React.Fragment>
    );
  }
}
