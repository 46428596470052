import React, { Component } from 'react';
import { Row, Col, Switch } from 'antd';
import { ApptTile } from '../Components/ApptTile/ApptTile';
import { connect } from 'react-redux';
import { fetchMyAppointments } from '../State/Actions/allActions'

class MyAppointmentPage extends Component {

  constructor(props) {
    super(props);
    // console.log(`MyAppointmentPage.props -> ${JSON.stringify(props)}`);
    this.props.dispatch(fetchMyAppointments());
  }

	state = {
    showEdit: false,
    showComplete: false,
    isFirstEdit: false
  }

  componentDidMount() {
    // if 'toTop' is set, scroll to top on window
    try {
      const { toTop } = this.props.location.state; 
      if(toTop) {
        window.scrollTo(0,0);
      }
    } catch(e) {
      // do nothing. 
    }
  }

  editApptFn(isNotSet, appt) {

    this.props.history.push('/appointment', { isNotSet, appt: appt, toTop: true });
    // this.setState({
    //   showEdit: !this.state.showEdit,
    //   isFirstEdit: isFirstEdit !== undefined ? isFirstEdit : this.state.isFirstEdit
    // });
  }

  toggleComplete(showComplete) {
    this.setState({
      showComplete
    });
  }

  render() {
    return (
      <div>
        <Row type="flex" justify="space-around" align="middle">
          <Col xs={{ span: 22 }} sm={{ span: 18 }} md={{ span: 16 }} lg={{ span: 12 }}>
            <div style={{ textAlign: 'center' }} >
              <h1 style={{ color: 'rgb(91, 18, 19)' }}>
                My Appointments
              </h1>
            </div>
            <Row type="flex" justify="space-around" align="middle">
              <Col>
                <span style={{ color: 'rgb(91, 18, 19)' }} >
                  {`Show Completed `}
                </span> 
                <Switch
                  checked={this.state.showComplete}
                  checkedChildren="YES"
                  unCheckedChildren="NO"
                  onChange={this.toggleComplete.bind(this)}
                />
              </Col>
            </Row>
            {!this.state.showComplete &&
              this.props.myAppointments.map((appt) => <ApptTile key={appt.requestId} appt={appt} editApptFn={this.editApptFn.bind(this)} /> )
            }
            {this.state.showComplete &&
              this.props.myCompletedAppointments.map((appt) => <ApptTile key={appt.requestId} isComplete appt={appt} editApptFn={this.editApptFn.bind(this)} /> )
            }
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    myAppointments: state.myAppointments,
    myCompletedAppointments: state.myCompletedAppointments
  };
};

export default connect(mapStateToProps)(MyAppointmentPage);