import React, { Component } from 'react';
import { Row, Col, Form, Icon, Input, Button } from 'antd';
import { loginSuccessful } from '../State/Actions/allActions'
import { connect } from 'react-redux';
import { Auth } from 'aws-amplify';
import { Link } from 'react-router-dom'

class LoginPage extends Component {
  // constructor(props) {
  //   super(props);
  //   console.log(`LoginPage.props -> ${JSON.stringify(props)}`);
  // }

  render() {
    return (
      <div className="nm-login-page">
        <Row type="flex" justify="space-around" align="middle">
          <Col>
            <LoginPanel succesfulSigninFn={() => {
              this.props.dispatch(loginSuccessful());
              this.props.history.push('/');
            }} />
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    isLoggedIn : state.isLoggedIn
  }
};

export default connect(mapStateToProps)(LoginPage);

class LoginPanel extends Component {

  state = {
    // used for setting password (new password, forgot password)
    user: null,
    email: null,

    showForm: "LOGIN", // LOGIN, NEWPASS
  }

  render() {
    return (
      <div className="nm-login-panel">
        {this.state.showForm === "NEWPASS"  && 
          <Row>
            <div style={{ paddingBottom: '0.75rem' }}>
              <span style={{
                color: "#5B1213"
              }}>
                You've succesfully entered your temporary password. <br/>
                Now input your permanent password.
              </span>
            </div>
          </Row>
        }
        <Row type="flex" justify="space-around" align="middle">
          <Col xs={{ span: 16 }} sm={{ span: 16 }}>
            <div style={{ paddingBottom: '0.75rem' }}>
              <span style={{
                fontSize: "large", fontWeight: "800",
                color: "#5B1213"
              }}>
                Notary Login
              </span>
            </div>
          </Col>
          <Col>
            {this.state.showForm === "LOGIN" &&
              <AntdLoginForm
                succesfulSigninFn={this.props.succesfulSigninFn}
                showNewPassWithUser={(user, email) => {
                  this.setState({
                    showForm: "NEWPASS",
                    user: user,
                    email: email
                  })
                }}
              />
            }
            {this.state.showForm === "NEWPASS" &&
              <AntdNewPasswordForm
                succesfulSigninFn={this.props.succesfulSigninFn}
                user={this.state.user}
                email={this.state.email}
              />
            }
          </Col>
        </Row>
      </div>
    );
  }
}

/**
 * ******************** LoginForm ******************
 */  
class LoginForm extends Component {

  handleSubmit = async (e) => {
    e.preventDefault();
    this.props.form.validateFields(async (err, values) => {
      if (!err) {

        const value = await this.signIn(values.username, values.password);

        switch(value.res) {
          case "SUCCESS":
            this.props.succesfulSigninFn();
            break;
          case "NEW_PASS":  
            this.props.showNewPassWithUser(value.user, values.username);
            break;
          case "FORGOT_PASS":
            this.props.showForgotPass(values.username);
            break;  
          case "ERROR":
            alert(value.message);
            break;
          default:  
        }
      } 
      else {
        console.error(err);
      }
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <Form onSubmit={this.handleSubmit} className="login-form">
        <Form.Item>
          {getFieldDecorator('username', {
            rules: [{ required: true, message: 'Please input your username!' }],
          })(
            <Input
              prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
              placeholder="Username"
              size="large"
            />,
          )}
        </Form.Item>
        <Form.Item>
          {getFieldDecorator('password', {
            rules: [{ required: true, message: 'Please input your Password!' }],
          })(
            <Input
              prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
              type="password"
              placeholder="Password"
              size="large"
            />,
          )}
        </Form.Item>
        <Form.Item>
          <div style={{ textAlign: 'center' }} >
            <Button type="primary" htmlType="submit" className="nm-login-submit">
              Login
            </Button>
          </div>
          <div className="nm-login-form-extras">
            {/* <a href="/forgot">Forgot password</a>  */}
            <Link to="/forgot">Forgot password</Link>
          </div>
          <div className="nm-login-form-extras">
            <a href="/">Apply to become a Notary!</a>
          </div>
        </Form.Item>
      </Form>
    );
  }

  signIn = async(username, password) => {
    try {
        const user = await Auth.signIn(username, password);

        if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
          const {requiredAttributes} = user.challengeParam; // the array of required attributes, e.g ['email', 'phone_number']
          // You need to get the new password and required attributes from the UI inputs
          // and then trigger the following function with a button click
          // For example, the email and phone_number are required attributes
          console.log(requiredAttributes);
          return {
            user,
            res: "NEW_PASS"
          };
      } else {
          // The user directly signs in
          return {
            user,
            res: "SUCCESS"
          };
      }
    } catch (err) {
      if (err.code === 'PasswordResetRequiredException') {
          // The error happens when the password is reset in the Cognito console
          // In this case you need to call forgotPassword to reset the password
          // Please check the Forgot Password part.
          console.log(err);
          return {
            res: "FORGOT_PASS",
            message: "Your password has been reset. Please reset your password through 'Forgot password' link."
          };
      } else if (err.code === 'NotAuthorizedException') {
          // The error happens when the incorrect password is provided
          console.log(err);
      } else if (err.code === 'UserNotFoundException') {
          // The error happens when the supplied username/email does not exist in the Cognito user pool
          console.log(err);
      } else {
          console.log(err);
      }
      return {
        res: "ERROR",
        message: err.message
      };
    }
  }
}

const AntdLoginForm = Form.create({ name: 'nm_login' })(LoginForm);

/**
 * 
 * ******************** NewPasswordForm ******************
 * 
 */

class NewPasswordForm extends Component {
  constructor(props) {
    super(props);
    console.log(`NewPasswordForm.props -> ${JSON.stringify(props)}`);
  }

  handleSubmit = async (e) => {
    e.preventDefault();
    this.props.form.validateFields(async (err, values) => {
      if (!err) {

        if(values.password !== values.passwordConfirm) {
          alert("New Password and New Password Confirm are not equal.");
          return;
        }

        const value = await this.doNewPassword(this.props.user, values.username, values.password);
        switch(value.case) {
          case "SUCCESS":
            this.props.succesfulSigninFn();
            setTimeout(() => alert(`Your password has been saved and you've been logged in.`), 300);
            break;
          case "ERROR":
            alert(value.msg);
            break;
          default:
        }
      } 
      else {
        console.error(err);
      }
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <Form onSubmit={this.handleSubmit} className="login-form">
        <Form.Item>
          {getFieldDecorator('username', {
            initialValue: this.props.email,
            rules: [{ required: true, message: 'Please input your username!' }],
          })(
            <Input
              prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
              placeholder="Username"
              size="large"
            />,
          )}
        </Form.Item>
        <Form.Item>
          {getFieldDecorator('password', {
            rules: [{ required: true, message: 'Please input your Password!' }],
          })(
            <Input
              prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
              type="password"
              placeholder="Password"
              size="large"
            />,
          )}
        </Form.Item>
        <Form.Item>
          {getFieldDecorator('passwordConfirm', {
            rules: [{ required: true, message: 'Please confirm your New Password' }],
          })(
            <Input
              prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
              type="password"
              placeholder="New Password Confirm"
              size="large"
            />,
          )}
        </Form.Item>
        <Form.Item>
          <span style={{ color: "#5B1213" }}>
            Password must fulfill the following:
          </span>
          <ul style={{ color: "#5B1213", lineHeight: "85%" }}>
            <li>Must be 8 characters long </li>
            <li>Contain a lowercase letter</li>
            <li>Contain a uppercase letter</li>
            <li>Contain a symbol character</li>
          </ul>
        </Form.Item>
        <Form.Item>
          <div style={{ textAlign: 'center' }} >
            <Button type="primary" htmlType="submit" className="nm-login-submit">
              Submit
            </Button>
          </div>
        </Form.Item>
      </Form>
    );
  }

  doNewPassword = async (user, email, newPassword) => {
    try {
        const loggedUser = await Auth.completeNewPassword(
          user,              // the Cognito User Object
          newPassword,       // the new password
        );
        // The user directly signs in
        return {
          case: "SUCCESS"
        };

    } catch (err) {
      if (err.code === 'PasswordResetRequiredException') {
          // The error happens when the password is reset in the Cognito console
          // In this case you need to call forgotPassword to reset the password
          // Please check the Forgot Password part.
          console.log(err);
          return {
            case: "ERROR",
            msg: "Your password has been reset. Please use Forgot Password link."
          };
      } else if (err.code === 'NotAuthorizedException') {
          // The error happens when the incorrect password is provided
          console.log(err);
          return {
            case: "ERROR",
            msg: err.message
          };
      } else if (err.code === 'UserNotFoundException') {
          // The error happens when the supplied username/email does not exist in the Cognito user pool
          console.log(err);
          return {
            case: "ERROR",
            msg: err.message
          };
      } else if (err.code === 'InvalidPasswordException') {
        // The error happens when the password does not meet the standard
        console.log(err);
        return {
          case: "ERROR",
          msg: err.message
        }
      } else {
          console.log(err);
      }
      return {
        case: "ERROR",
        msg: "Invalid Credentials"
      };
    }
  }
}

const AntdNewPasswordForm = Form.create({ name: 'nm_login_newpass' })(NewPasswordForm);
