import React, { Component } from 'react';
import NMNavbar from './Components/Navbar/NMNavBar';
import MainPage from './Pages/MainPage';
import LoginPage from './Pages/LoginPage';
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";
import { connect } from 'react-redux';
import { logoutUser, logoutSuccessful, loginSuccessful } from './State/Actions/allActions'
import MyAppointmentPage from './Pages/MyAppointmentPage';
import EditAppointmentPage from './Pages/EditAppointmentPage';
import { MyPreferencesPage } from './Pages/MyPreferencesPage';
import ForgotPage from './Pages/ForgotPage'

import Amplify, { Auth } from 'aws-amplify';
import config from './config';

// console.log(config)

Amplify.configure({
  Auth: {

      // REQUIRED - Amazon Cognito Region
      region: config.cognitoRegion,

      // OPTIONAL - Amazon Cognito User Pool ID
      userPoolId: config.cognitoPoolId,

      // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
      userPoolWebClientId: config.cognitoClientId,

      // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
      mandatorySignIn: false,

      // OPTIONAL - Manually set the authentication flow type. Default is 'USER_SRP_AUTH'
      authenticationFlowType: 'USER_SRP_AUTH',

    }
});

class App extends Component {

  state = {
    redirectMyAppts: false,
    redirectMyPrefs: false,
    redirectLogin: false,
    redirectHome: false
  }

  componentDidUpdate() {
    // if redirectMyAppts == true, flip it back so redirect logic can continue to work
    if(this.state.redirectMyAppts) {
      this.setState({
        redirectMyAppts: false
      })
    }

    // if redirectMyPrefs == true, flip it back so redirect logic can continue to work
    if(this.state.redirectMyPrefs) {
      this.setState({
        redirectMyPrefs: false
      })
    }

      // if redirectMyPrefs == true, flip it back so redirect logic can continue to work
      if(this.state.redirectLogin) {
        this.setState({
          redirectLogin: false
        })
      }

    // if redirectHome == true, flip it back so redirect logic can continue to work
    if(this.state.redirectHome) {
      this.setState({
        redirectHome: false
      })
    }
  }

  render() {
    return (
      <Router >
        {/* redirect to My Appointments */}
        {this.state.redirectMyAppts && <Redirect to='/myappointments' push={true}/>}

        {/* redirect to My Preferences */}
        {this.state.redirectMyPrefs && <Redirect to='/preferences' push={true}/>}

        {/* redirect to Login */}
        {this.state.redirectLogin && <Redirect to='/login' push={true}/>}

        {/* redirect to Main Page */}
        {this.state.redirectHome && <Redirect to='/' push={true}/>}

        <div className="App">
          <header className="App-header">
            <NMNavbar 
              homeRedirect={() => {
                this.setState({
                  redirectHome: true
                })
              }}
              myApptsRedirect={() => {
                this.setState({
                  redirectMyAppts: true
                })
              }}
              // myPrefsRedirect={() => {
              //   this.setState({
              //     redirectMyPrefs: true
              //   })
              // }}              
              logoutFn={() => {
                this.props.dispatch(logoutUser());
                setTimeout(() => {
                  this.setState({
                    redirectLogin: true
                  })
                }, 500)
              }}
            />
          </header>
            <Switch>
              <PrivateRoute
                setLoggedIn={this.setLoggedIn.bind(this)}
                exact
                path="/"
                render={props => <MainPage {...props}/> }
              />
              <PrivateRoute
                setLoggedIn={this.setLoggedIn.bind(this)}
                path="/myappointments"
                render={props => <MyAppointmentPage {...props}/> }
              />
              <PrivateRoute
                setLoggedIn={this.setLoggedIn.bind(this)}
                path="/appointment"
                render={props => <EditAppointmentPage {...props}/> }
              />
              {/* <PrivateRoute
                setLoggedIn={this.setLoggedIn.bind(this)}
                path="/preferences"
                render={props => <MyPreferencesPage {...props}/> }
              /> */}
              <Route
                path="/login"
                render={props => <LoginPage {...props}/> }
              />
              <Route
                path="/forgot"
                render={props => <ForgotPage {...props}/> }
              />
            </Switch>
        </div>
      </Router>
    );
  }

  setLoggedIn(isLoggedIn) {
    if(isLoggedIn) {
      this.props.dispatch(loginSuccessful());
    }
    else {
      this.props.dispatch(logoutSuccessful());
    }
  }
}



/**
A wrapper for <Route> that redirects to the login screen if you're not yet authenticated.
*/
class PrivateRoute extends Component {

  state = {
    refreshIntervalId: 0,
    lastChecked: null,
    isLoggedIn: 0 // -1 = false, 0 = null, 1 = true
  }

  checkAuth() {
    // bypassCache is optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
    Auth.currentAuthenticatedUser({bypassCache: false})
      .then(user => {
        // console.log(user);
        this.setState({ isLoggedIn: 1 })
        this.props.setLoggedIn(true);
      })
      .catch(err => {
        // console.log(err);
        this.setState({ isLoggedIn: -1 })
        this.props.setLoggedIn(false);
      });
  }

  componentDidMount() {
    this.checkAuth();

    const refreshIntervalId = setInterval(() => {
      this.checkAuth();
    }, 600000) // every 10 minutes

    this.setState({
      refreshIntervalId: refreshIntervalId
    })
  }

  componentWillUnmount() {
    // console.log(`clearInterval-ing ${this.props.path}`);
    clearInterval(this.state.refreshIntervalId);
  }

  render() {
    // console.log(`rendering ${this.props.path}`);

    // spread props into rest but remove children, location, computedMatch, setLoggedIn
    const {
      children, location, computedMatch, setLoggedIn,
      ...rest
    } = this.props;

    // console.log(`rest -> ${Object.keys(rest)}`);
    // console.log(`props -> ${Object.keys(this.props)}`);

    if(this.state.isLoggedIn === -1) {

      return(
        <Redirect
          to={{
            pathname: "/login",
            state: { from: this.props.location }
          }}
        />
      );
    }
    else if(this.state.isLoggedIn === 1) {
      return(
        <Route
        {...rest}
      />
      );
    }

    // show nothing until auth is determined
    return (
      <div></div>
    );
  }
}

const mapStateToProps = state => {
  return {}
};

export default connect(mapStateToProps)(App);
