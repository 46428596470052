
const defaultConfigs = {
  requestServiceUrl: process.env.REACT_APP_REQUEST_SERVICE_URL,
  pricingServiceUrl: process.env.REACT_APP_PRICING_SERVICE_URL,
  apptServiceUrl: process.env.REACT_APP_APPT_SERVICE_URL,

  cognitoRegion: process.env.REACT_APP_COGNITO_REGION,
  cognitoPoolId: process.env.REACT_APP_COGNITO_POOL_ID,
  cognitoClientId: process.env.REACT_APP_COGNITO_CLIENT_ID,

  pricingServiceKey: process.env.REACT_APP_PRICING_SERVICE_KEY
};

export default defaultConfigs;