import { 
  LOGIN_SUCCESS, LOGOUT_SUCCESS,
  FETCHED_NEW_ERQUESTS_ERROR, FETCHED_NEW_ERQUESTS_SUCCESS,
  FETCHED_MY_APPTS_ERROR, FETCHED_MY_APPTS_SUCCESS,
  FETCHED_APPT_ERROR, FETCHED_APPT_SUCCESS
} from '../Actions/allActions'

export function allReducers(state, action) {

  switch(action.type) {
    case LOGIN_SUCCESS:
      return { ...state, 
        isLoggedIn: true 
      }
    case LOGOUT_SUCCESS:
      return { ...state, 
        isLoggedIn: false 
      }
    
    case FETCHED_NEW_ERQUESTS_SUCCESS:
      return { ...state, 
        allNewRequests: action.payload.requests,
        lastFetched: { ...state.lastFetched,
          newRequests: Date.now()
        }
      }  
    case FETCHED_NEW_ERQUESTS_ERROR:
      return state;

    case FETCHED_MY_APPTS_ERROR:
      return state;

    case FETCHED_MY_APPTS_SUCCESS:
      const newAppointments = action.payload.appointments;
      newAppointments.sort((a, b) => {
        if(a.apptStatus === "CLAIMED") {
          return b.apptStatus != "CLAIMED" ? -1 : b.createdAt - a.createdAt;
        }
        return b.updatedAt - a.updatedAt;
      });

      const inProgressAppts = [];
      const completedAppts = [];

      for(const a of newAppointments) {
        if(a.apptStatus === "COMPLETE") {
          completedAppts.push(a);
        }
        else {
          inProgressAppts.push(a);
        }
      }

      return { ...state,
        myAppointments: inProgressAppts,
        myCompletedAppointments: completedAppts,
        lastFetched: { ...state.lastFetched,
          myAppointments: Date.now()
        }
      }

    case FETCHED_APPT_ERROR:
      return state;
  
    case FETCHED_APPT_SUCCESS:
      const appt = action.payload.appointment;
      let apptsCopy = [...state.myAppointments];

      // replace appt with the one just fetched
      for (let i = 0; i < apptsCopy.length; i++) {
        if (apptsCopy[i].requestId === appt.requestId) {
          apptsCopy.splice(i, 1, appt);
          break;
        }
      }

      // console.log(`FETCHED_APPT_SUCCESS`)
      // console.log(JSON.stringify(state.myAppointments))
      // console.log(JSON.stringify(apptsCopy))

      return { ...state,
        myAppointments: apptsCopy
      }

    default:
      return state;
  }

}
