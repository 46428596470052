
export function formatMoney(amount, decimalCount = 2, decimal = ".", thousands = ",") {
  try {
    decimalCount = Math.abs(decimalCount);
    decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

    let i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString();
    let j = (i.length > 3) ? i.length % 3 : 0;

    return (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) + (decimalCount ? decimal + Math.abs(amount - i).toFixed(decimalCount).slice(2) : "");
  } catch (e) {
    // console.log(e)
    return amount;
  }
};

/**
 * converts time to US standard format
 * i.e
 *  07:30 => 7:30 am
 *  17:30 => 5:30 pm
 */
export function timeToEnglish(timeStr) {

  const parts = timeStr.split(":");
  const hours = parseInt(parts[0]);
  const mins = parseInt(parts[1]);

  const hoursStr = hours == 0 ? 12 : hours > 12 ? hours - 12 : hours;
  const minutesStr = (mins < 10 ? "0" : "") + mins;
  const ampm = hours < 12 ? "AM" : "PM";

  const formattedTime = `${hoursStr}:${minutesStr} ${ampm}`;

  // console.log(`formatted ${timeStr} => ${formattedTime}`)

  return formattedTime;
}

export function docTypeDisplay(type) {
  return docTypeDisplayNames[type];
}

export function allDocTypeSelection() {
  return Object.entries(docTypeDisplayNames).map(([k, v]) => {
    return { key: k , value: v }
  })
}

const docTypeDisplayNames = {
  "POWER_ATTORNEY" : "Power of Attorney",
  "WILL" : "Will",
  "LEASE_AGREEMENT" : "Lease Agreement",
  "LOAN_SIGNING_PURCHASE" : "Loan Signing-Purchase",
  "LOAN_SIGNING_SELL" : "Loan Signing-Sellor",
  "CLAIM_DEED" : "Quit Claim Deed",
  "TRAVEL_CONSENT" : "Travel Consent",
  "VEHICLE_LICENSING" : "Vehicle Licensing",
  "EASEMENT" : "Easement",
  "APOSTILLE" : "Apostille",
  "OTHER" : "Other",
  "NOT_SURE" : "Not Sure",
};