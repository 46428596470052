import React, { Component } from 'react';
import { Row, Col, Form, Icon, Input, Button, Checkbox, Select } from 'antd';

export class MyPreferencesPage extends Component {
	state = {
    // visible: false
  }

  render() {
    return (
      <div>
        <Row type="flex" justify="space-around" align="middle">
          <Col xs={{ span: 24 }} sm={{ span: 18 }} md={{ span: 12 }}>
            <div style={{ textAlign: 'center' }} >
              <h1 style={{ color: 'rgb(91, 18, 19)' }}>
                My Preferences
              </h1>
            </div>
          </Col>
        </Row>
        <Row type="flex" justify="space-around" align="middle">
          <Col xs={{ span: 22 }} sm={{ span: 18 }} md={{ span: 12 }}>
            <AntdMyPreferencesForm />
          </Col>
        </Row>
      </div>
    );
  }
}

class MyPreferencesForm extends Component {

  state = {
    notifyOptions : [
      { label: 'Email', value: 'email' },
      { label: 'Phone (text)', value: 'phone' },
    ],
    timeOptions : [
      { key: 0 , value: '12am' }, { key: 1 , value: '1am' }, { key: 2 , value: '2am' }, { key: 3 , value: '3am' },
      { key: 4 , value: '4am' }, { key: 5 , value: '5am' }, { key: 6 , value: '6am' }, { key: 7 , value: '7am' },
      { key: 8 , value: '8am' }, { key: 9 , value: '9am' }, { key: 10 , value: '10am' }, { key: 11 , value: '11am' },
      { key: 12 , value: '12pm' }, { key: 13 , value: '1pm' }, { key: 14 , value: '2pm' }, { key: 15 , value: '3pm' },
      { key: 16 , value: '4pm' }, { key: 17 , value: '5pm' }, { key: 18 , value: '6pm' }, { key: 19 , value: '7pm' },
      { key: 20 , value: '8pm' }, { key: 21 , value: '9pm' }, { key: 22 , value: '10pm' }, { key: 23 , value: '11pm' },
    ]
  }

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        console.log('Received values of form: ', values);
      } 
      else {
        console.error(err);
      }
    });
  };

  notifyOnChange(selectedNotifyOptions) {
    console.log('notifyOnChange.checked = ', selectedNotifyOptions);
  }

  timePrefsOnChange(selectedTimeOptions) {
    console.log('timePrefsOnChange.checked = ', selectedTimeOptions);
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <Form onSubmit={this.handleSubmit} className="login-form">
        <Form.Item>
          {getFieldDecorator('email', {
            rules: [{ required: true, message: 'Please input an email!' }],
          })(
            <Input
              prefix={<Icon type="form" style={{ color: 'rgba(0,0,0,.25)' }} />}
              placeholder="My Email"
              size="large"
            />,
          )}
        </Form.Item>
        <Form.Item>
          {getFieldDecorator('phoneNo', {
            rules: [{ required: true, message: 'Please input your Phone #!' }],
          })(
            <Input
              prefix={<Icon type="phone" style={{ color: 'rgba(0,0,0,.25)' }} />}
              placeholder="My Phone #"
              size="large"
            />,
          )}
        </Form.Item>
        <Form.Item>
          <div>
            How would you like to Notary Match to notify you?
          </div>
          {getFieldDecorator("notifyOptions", {}) (
            <Checkbox.Group options={this.state.notifyOptions} onChange={this.notifyOnChange} />
          )}
        </Form.Item>
        <Form.Item>
          <div>
            Which time(s) would like Notary Match to <b>NOT</b> notify you?
          </div>
          <Select
            mode="multiple"
            style={{ width: '100%' }}
            placeholder="Please select"
            defaultValue={['0', '1', '2', '3', '4', '5']}
            onChange={this.timePrefsOnChange}
          >
            {this.state.timeOptions.map(option => (<Select.Option key={option.key}> {option.value} </Select.Option>))}
          </Select>
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit" className="nm-login-submit">
            Submit
          </Button>
        </Form.Item>
      </Form>
    );
  }
}

const AntdMyPreferencesForm = Form.create({ name: 'nm_prefs' })(MyPreferencesForm);