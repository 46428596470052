import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Row, Col } from 'antd';
import { RequestTile } from '../Components/RequestTile/RequestTile';
import { getNewRequestsByMetro } from '../State/Actions/allActions'

class MainPage extends Component {

  constructor(props) {
    super(props)
    // console.log(`MainPage.props: ${JSON.stringify(props)}`)
    // TODO: get metro area by user
    this.props.dispatch(getNewRequestsByMetro('SEA'));
  }

	state = {
  }

  render() {

    this.props.allNewRequests.sort((a,b) => {
      return b.createdAt - a.createdAt;
    });

    return (
      <div>
        <Row type="flex" justify="space-around" align="middle">
          {/* <Col xs={{ span: 24 }} sm={{ span: 18 }} md={{ span: 12 }}> */}
          <Col xs={{ span: 22 }} sm={{ span: 18 }} md={{ span: 16 }} lg={{ span: 12 }}>

            <div style={{ textAlign: 'center' }} >
              <h1 style={{ color: 'rgb(91, 18, 19)' }}>
                Customer Requests
              </h1>
            </div>
            {this.props.allNewRequests.map((r) => <RequestTile request={r} key={r.requestId} />)}
            {/* <RequestTile /> */}
            {/* <RequestTile /> */}
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    allNewRequests: state.allNewRequests
  };
};

export default connect(mapStateToProps)(MainPage);