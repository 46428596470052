import React, { Component } from 'react';
import { Row, Col, Form, Input, Button, Checkbox, Select, DatePicker, TimePicker, Switch, Slider, Modal } from 'antd';
import Geosuggest from 'react-geosuggest';
import { Redirect } from 'react-router-dom'
import moment from 'moment'
import { setAppointment, editAppointment, calculatePrices, completeAppointment } from '../State/Fetch/allFetches'
import { getAppointmentAndUpdate } from '../State/Actions/allActions'
import { docTypeDisplay, formatMoney } from '../Util/display'
import { connect } from 'react-redux';
import { diff } from '../Util/helper'
import { allDocTypeSelection } from '../Util/display'

class EditAppointmentPage extends Component {
  
  constructor(props) {
    super(props);
    // console.log(`EditAppointmentPage.props -> ${JSON.stringify(props)}`);
  }

	state = {
    showEdit: false,
    showComplete: false
  }

  componentDidMount() {
    // if 'toTop' is set, scroll to top on window
    try {
      const { toTop } = this.props.location.state; 
      if(toTop) {
        window.scrollTo(0,0);
      }
    } catch(e) {
      // do nothing. 
    }
  }

  backToMyAppts() {
    this.props.history.push('/myappointments', { toTop: true });
  }

  toggleComplete() {
    this.setState({
      showComplete: !this.state.showComplete
    });
  }

  convertToRequest(appt) {

    const { apptDetails } = appt;

    let request = {
      requestId: appt.requestId,
      firstName: appt.firstName,
      lastName: appt.lastName,
      docType: appt.docType,
      email: appt.email
    }

    if(apptDetails) {
      request.apptDetails = {
        signatureCount: parseInt(apptDetails.signatureCount, 10),
        apptDate: apptDetails.apptDate,
        apptTime: apptDetails.apptTime,
        apptEpoch: apptDetails.apptEpoch,
        apptAddress: apptDetails.apptAddress,
        customerPrice: parseInt(apptDetails.customerPrice, 10),
        notaryPay: parseInt(apptDetails.notaryPay, 10),
        additionals: apptDetails.additionals
      };
    }
    if(apptDetails.apptLandmark) {
      request.apptDetails.apptLandmark = apptDetails.apptLandmark;
    }
    if(apptDetails.apptNotes) {
      request.apptDetails.apptNotes = apptDetails.apptNotes;
    }

    return request;
  }

  /**
   * 
   */
  setOrEditAppt(requestToConfirm, isNotSet, cb) {

    const request = {...requestToConfirm};

    if(isNotSet) {
      return setAppointment(request)
        .then(() => {
          this.props.dispatch(getAppointmentAndUpdate(request.requestId));
          cb(null);
        })
        .catch((err) => cb(err));

    }
    else {
      const { appt } = this.props.location.state;

      /**
       * Convert current 'appt' to the equivalent 'request'
       * Get diff from current appt and the 'new' edited appt
       * send diff to '/editAppointment'
       */
      const currentAppointment = this.convertToRequest(appt);
      const diffToEdit = diff(currentAppointment, request);
    
      // console.log(appt);
      // console.log(currentAppointment);
      // console.log(request);
      // console.log(diffToEdit);
      // console.log(JSON.stringify(diffToEdit));
      // console.log(JSON.stringify(difference2));

      if(Object.keys(diffToEdit).length === 0) {
        return cb('Triggered an edit without any changes', `No changes were made.`);
      }

      // if the price has changed, don't send edit. we currently don't support this feature
      if(diffToEdit.apptDetails && diffToEdit.apptDetails.customerPrice) {
        return cb(
          `Price changes aren't supported.`,
          `Currently, price changes from editing an appointment aren't supported. Contact your Notary Match representative. Have the Request Id and your Notary Id.`);
      }

      return editAppointment({
          ...diffToEdit,
          requestId: request.requestId 
        })
        .then(() => {
          this.props.dispatch(getAppointmentAndUpdate(request.requestId));
          cb(null);
        })
        .catch((err) => cb(err))
    }

  }


  render() {
    let isNotSet, appt;

    try {
      const stateFromMyAppts = this.props.location.state;
      isNotSet = stateFromMyAppts.isNotSet;
      appt = stateFromMyAppts.appt;

    } catch(e) {
      // user tried to go directly to edit page
      return <Redirect to='/myappointments' push={true}/>;
    }

    if(this.state.showComplete) {
      return (
        <div>
          <Row type="flex" justify="space-around" align="middle">
            <Col xs={{ span: 22 }} sm={{ span: 18 }} md={{ span: 16 }} lg={{ span: 12 }}>
              <div style={{ textAlign: 'center' }} >
                <h1 style={{ color: 'rgb(91, 18, 19)' }}>
                  Complete Apppointment
                </h1>
              </div>
              <div>
                <CompleteAppointmentPanel
                  appt={appt}
                  onExit={this.toggleComplete.bind(this)}
                />
              </div>
            </Col>
          </Row>
        </div>
      );
    }

    return (
      <div>
        <Row type="flex" justify="space-around" align="middle">
          <Col xs={{ span: 22 }} sm={{ span: 18 }} md={{ span: 16 }} lg={{ span: 12 }}>
            <div style={{ textAlign: 'center' }} >
              <h1 style={{ color: 'rgb(91, 18, 19)' }}>
                {isNotSet ? "Set Appointment" : "Edit Appointment"} 
              </h1>
            </div>
            <div>
              <EditAppointmentPanel
                isNotSet={isNotSet}
                appt={appt}
                onExit={this.backToMyAppts.bind(this)}
                toggleComplete={this.toggleComplete.bind(this)}
                setOrEditAppt={this.setOrEditAppt.bind(this)} />
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

class EditAppointmentPanel extends Component {
  // constructor(props) {
  //   super(props);
  //   console.log(`EditAppointmentPanel.props -> ${JSON.stringify(props)}`);
  // }

  render() {

    const { appt } = this.props;

    return (
      <div>
        <Row>
          <Col xs={{ span: 0 }} sm={{ span: 12 }} >
            <span style={{ fontSize: "x-large", fontWeight: "800",
                                letterSpacing: "0.2em", color: "#5B1213" }}>
              {appt.requestId}
            </span>
          </Col>
          <Col xs={{ span: 12 }} sm={{ span: 0 }} >
            <span style={{ fontSize: "large", fontWeight: "800",
                                letterSpacing: "0.1em", color: "#5B1213" }}>
              {appt.requestId}
            </span>
          </Col>

          <Col xs={{ span: 0 }} sm={{ span: 0 }} md={{ span: this.props.isNotSet ? 0 : 12 }} style={{ textAlign: 'right' }}>
            <div >
              <Button icon="book" size="large" className="nm-appt-complete-btn" onClick={this.props.toggleComplete}>
                <span style={{ color: "#5B1213" }}>
                  Mark Appointment as Complete
                </span>
              </Button>
            </div>
          </Col>
          <Col xs={{ span: this.props.isNotSet ? 0 : 12 }} sm={{ span: this.props.isNotSet ? 0 : 12 }} md={{ span: 0 }} style={{ textAlign: 'right' }}>
            <div >
              <Button icon="book" className="nm-appt-complete-btn" onClick={this.props.toggleComplete}>
                <span style={{ color: "#5B1213" }}>
                  Mark Complete
                </span>
              </Button>
            </div>
          </Col>
          
          <Col span={24} >
            <AntdEditAppointmentForm appt={appt} isNotSet={this.props.isNotSet} onExit={this.props.onExit} setOrEditAppt={this.props.setOrEditAppt} />
          </Col>
        </Row>   
      </div>
    );
  }
}

class EditAppointmentForm extends Component {
  constructor(props) {
    super(props);
    // console.log(`EditAppointmentForm.props -> ${JSON.stringify(props)}`);

    const { apptDetails, docType } = props.appt;
    const { apptDate, apptTime, apptEpoch, apptAddress, additionals, signatureCount } = apptDetails || {};

    this.state = {
      // set necessary appt state values from props
      signatureCount: signatureCount || 1,
      apptDate: apptDate,
      apptTime: apptTime,
      apptEpoch: apptEpoch,
      apptAddress: apptAddress,
      docType: docType,
      additionals: additionals || [],

      // UI 
      docTypeOptions : allDocTypeSelection(),
      showConfirmAppt: false,
      prices: {
        basePrice: 0,
        signaturePrice: 0,
        totalPrice: 0
      },

      // confirm set/edit appt
      showAutoComplete: true,
      requestToConfirm: null
    }

  }

  reCalculate() {
    
    const { docType, signatureCount, additionals } = this.state;

    // console.log(`docType, signatureCount --> ${docType}, ${signatureCount}`);

    if(docType && signatureCount) {
      calculatePrices(docType, this.props.appt.metroArea, signatureCount, additionals)
        .then((res) => {
          // console.log(res);
          this.setState({
            prices: res.data.prices
          });
        });
    }
  }

  componentDidMount() {
    this.reCalculate();
  }

  componentDidUpdate(prevProps, prevState) {

    if(prevState.docType !== this.state.docType
      || prevState.signatureCount !== this.state.signatureCount
      || prevState.additionals.length > this.state.additionals.length) {
        this.reCalculate();
    }
  }

  onCancel = () => {
    this.setState({
      showConfirmAppt: false
    });
  };

  onYes = () => {
    this.props.setOrEditAppt(this.state.requestToConfirm, this.props.isNotSet, (err, msg) => {
      let message = '';

      if(err) {
        console.error(err);
        message = msg ? msg : 'An error occurred. Please ensure internet connectivity and try again.';
      }
      else {
        message = `Succesfully ${this.props.isNotSet ? 'set' : 'edit'} appointment ${this.props.appt.requestId}.`;
      }

      // after submit set/edit appt
      this.setState({ 
        showConfirmAppt: false
      })
      setTimeout(() => alert(message), 200)
      // this.props.onExit();
    })
  };


  docTypeOnChange(selectedDocType) {
    // console.log(`selectedDocType -> ${selectedDocType}`);
    this.setState({
      docType: selectedDocType
    });
  }

  signatureCountOnChange(sigCount) {
    // console.log(`sigCount -> ${JSON.stringify(sigCount)}`);
    // console.log(`sigCount -> ${sigCount}`);
    this.setState({
      signatureCount: sigCount
    });
  }

  autoCompleteLocationChange(showAutoComplete) {
    // console.log(`showAutoComplete -> ${showAutoComplete}`);
    this.setState({
      showAutoComplete: showAutoComplete
    })
  }

  addLabelChange(val, index) {    
    let additional = this.state.additionals[index];
    additional.label = val;

    this.setState({
      additionals: this.state.additionals
    });
  }

  addPriceChange(val, index) {
    let additional = this.state.additionals[index];

    // console.log(`val.length === 0 -> ${val.length === 0}`);
    // console.log(`val == null -> ${val == null}`);
    // console.log(`isNaN(val) -> ${isNaN(val)}`);

    const thePrice = val.length === 0 ? 0 : parseInt(val);
    // console.log(`thePrice -> ${thePrice}`);

    additional.price = thePrice;
    this.reCalculate();

    this.setState({
      additionals: this.state.additionals
    });
  }

  handleSubmit = e => {
    e.preventDefault();
    const values = this.props.form.getFieldsValue();
    // console.log(`values --> ${JSON.stringify(values)}`);

    const request = this.buildAppointmentRequest(values);

    if(this.isValidRequest(request)) {
      this.setState({
        showConfirmAppt: true,
        requestToConfirm: request
      });
    }
    
  };

  render() {
    const { appt } = this.props;
    const { getFieldDecorator } = this.props.form;
    
    // console.log(`rendering. state -> ${JSON.stringify(this.state)}`)
    // console.log(`requestToConfirm => ${JSON.stringify(this.state.requestToConfirm)}`)

    return (
      <Form onSubmit={this.handleSubmit} className="appt-form">
      <Form.Item>
        First Name
        {getFieldDecorator('firstName', {
          initialValue: appt.firstName,
        })(
          <Input
            placeholder="First Name"
          />,
        )}
      </Form.Item>
      <Form.Item>
        Last Name
        {getFieldDecorator('lastName', {
          initialValue: appt.lastName,
        })(
          <Input
            placeholder="Last Name"
          />,
        )}
      </Form.Item>
      <Form.Item>
        Email Address
        {getFieldDecorator('email', {
          initialValue: appt.email,
          rules: [
            { type: 'email', message: 'The input is not a valid email address' }]
        })(
          <Input
            placeholder="email@address.com"
          />,
        )}
      </Form.Item>
      <Form.Item>
        <div>
          Document Type
        </div>
        <Select
          style={{ width: '100%' }}
          placeholder="Please select a document type"
          defaultValue={appt.docType}
          onChange={this.docTypeOnChange.bind(this)}
        >
          {this.state.docTypeOptions.map(option => (<Select.Option key={option.key}> {option.value} </Select.Option>))}
        </Select>
      </Form.Item>
      <Form.Item>
        # of Notarized Signatures
        {/* {getFieldDecorator('signatures', {
          initialValue: appt.apptDetails?.signatureCount ? appt.apptDetails.signatureCount : 1
        })( */}
          <Input
            type="number"
            placeholder="# of Signatures"
            defaultValue={appt.apptDetails?.signatureCount ? appt.apptDetails.signatureCount : 1}
            // onChange={this.signatureCountOnChange.bind(this)}
            onChange={(e) => this.signatureCountOnChange(e.target.value)}

          />
      </Form.Item>

      <Form.Item>
        <Row>
          <Col xs={{ span:10 }} sm={{ span:12 }} >
            <div>
              Appointment Date
            </div>
            <DatePicker
              // defaultValue={moment('01-06-2020', 'MM-DD-YYYY')}
              defaultValue={this.state.apptDate ? moment(this.state.apptDate, 'MM-DD-YYYY') : null}
              placeholder="Select Date"
              format="MM-DD-YYYY"
              inputReadOnly={true}
              onChange={this.onDateChange.bind(this)} onOk={this.onDateChange.bind(this)} 
            />
          </Col>
          <Col xs={{ span:14 }} sm={{ span:12 }} >
            <div>
              Appointment Time
            </div>
            <TimePicker
              // defaultValue={moment('13:30', 'HH:mm')}
              defaultValue={this.state.apptTime ? moment(this.state.apptTime, 'HH:mm') : null}
              placeholder="Select Time"
              format="HH:mm"
              minuteStep={5}
              use12Hours={true}
              hideDisabledOptions={true}
              inputReadOnly={true}
              getPopupContainer={trigger => trigger.parentElement}
              onChange={this.onTimeChange.bind(this)} onOk={this.onTimeChange.bind(this)} 
            />          
          </Col>
        </Row>
      </Form.Item>

      <Form.Item>
        {/* <div> */}
          <Row type="flex" justify="space-around">
            <Col span={12} >
              <span>Appointment Address</span>
            </Col>
            <Col span={12} style={{ textAlign: 'right' }}>
              <span>Autocomplete </span> 
              <Switch
                checked={this.state.showAutoComplete}
                checkedChildren="ON"
                unCheckedChildren="OFF"
                onChange={this.autoCompleteLocationChange.bind(this)} />
            </Col>
          </Row>          

        {/* </div> */}
        {this.state.showAutoComplete 
          ? (
            <Geosuggest
              ref={el=>this._geoSuggest=el}
              inputClassName="ant-input ant-input-lg"
              suggestsClassName="ant-select-dropdown-menu ant-select-dropdown-menu-root ant-select-dropdown-menu-vertical"
              onChange={(value) => {
                this.setState({
                  apptAddress: value
                })
              }}
              onSuggestSelect={(suggest) => {
                // console.log(`onSuggestSelect: ${JSON.stringify(suggest)}`)
                if(suggest !== undefined && suggest.gmaps !== undefined) {
                  this.setState({
                    apptAddress: suggest.gmaps.formatted_address
                  })
                }
              }}
              initialValue={this.state.apptAddress}
              country="US"
              placeDetailFields={['formatted_address', 'geometry']}
            />
          )
          : (
            <Input
              defaultValue={this.state.apptAddress}
              placeholder="Appointment Adress"
              size="large"
              onChange={(e) => {
                this.setState({
                  apptAddress: e.target.value
                })
              }}
            />
          )
        }
        {this.state.apptAddress &&
          <div style={{ textAlign: 'center' }}>
              <a 
                target="_blank"
                rel="noopener noreferrer"
                href={`http://maps.google.com/?q=${this.state.apptAddress}`}
                style={{ 
                  backgroundColor: 'rgb(91, 18, 19)', color: 'white',
                  padding: '0.3rem', borderRadius: '0.5rem'
                }}
              >
                View in Google Maps
              </a>
          </div>
        }
      </Form.Item>

      <Form.Item>
        Appointment Landmark (Optional)
        {getFieldDecorator('landmark', {
          initialValue: appt.apptDetails?.apptLandmark ? appt.apptDetails.apptLandmark : null
        })(
          <Input
            placeholder="i.e - Starbucks, Public Library, Customer's Home"
            size="large"
          />,
        )}
      </Form.Item>

      <Form.Item>
        Appointment Notes (Optional)
        {getFieldDecorator('notes', {
          initialValue: appt.apptDetails?.apptNotes ? appt.apptDetails.apptNotes : null
        })(
          <Input.TextArea
            placeholder="Add any pertinent notes from communication with the customer. i.e - Customer is vision impaired, Need a witness, Beware of owner's dog, Needs copies and/or delivery to a specifed location"
            size="large"
          />,
        )}
      </Form.Item>

      <Form.Item>
        Additional Services (Optional)
        {this.state.additionals.length > 0 &&
          <Row >
            <Col xs={{ span:16 }} sm={{ span:14 }}>
              Label
            </Col>
            <Col xs={{ span:0 }} sm={{ span:8 }}>
              Price (Dollars)
            </Col>
            <Col xs={{ span:6 }} sm={{ span:0 }}>
              Price ($)
            </Col>
          </Row>
        }
        {this.state.additionals.map((additional, index) => {
          return (
            <Row key={index}>
              <Col xs={{ span:16 }} sm={{ span:14 }} style={{ paddingRight: '0.25rem' }}>
                <Input
                  placeholder="i.e - Print Document, Deliver Document, Scan Document"
                  value={additional.label}
                  onChange={(e) => this.addLabelChange(e.target.value, index)}
                />
              </Col>
              <Col xs={{ span:6 }} sm={{ span:8 }} style={{ paddingRight: '0.25rem' }}>
                <Input
                  placeholder={15}
                  type="number"
                  value={additional.price === 0 ? "" : additional.price}
                  onChange={(e) => this.addPriceChange(e.target.value, index)}
                />
              </Col>
              <Col xs={{ span:2, offset:0 }} style={{ textAlign: 'right' }} >
                <Button 
                  type="primary" icon="minus" size="small"
                  onClick={() => {
                    // console.log(`removing index: ${index}`);
                    let newAdditionals = [...this.state.additionals];
                    // console.log(`additionals: ${JSON.stringify(this.state.additionals)}`);
                    // console.log(`newAdditionals: ${JSON.stringify(newAdditionals)}`);

                    newAdditionals.splice(index, 1);
                    // console.log(`newAdditionals: ${JSON.stringify(newAdditionals)}`);

                    this.setState({ 
                      additionals: newAdditionals
                    })
                  }}
                >
                </Button>
              </Col>
              {/* <Col xs={{ span:23 }} sm={{ span:0 }}>
                <hr/>
              </Col> */}
            </Row>
          );
        })}
        <div>
          <Button 
            type="primary" icon="plus" size="small"
            onClick={() => {
              // console.log(`additionals: ${JSON.stringify(this.state.additionals)}`);

              this.setState({ 
                additionals: [...this.state.additionals, {}] 
              })
            }}
          >
            Add Service
          </Button>
        </div>
      </Form.Item>

      <Form.Item type="flex" justify="start" >
        <div>
          <span style={{ fontWeight: "800", color: "#5B1213" }}>Quoted Customer Price: </span> {this.props.appt.estimatePrice ? `$${this.props.appt.estimatePrice}` : 'N/A'}
        </div>
        <div >
          <span style={{ fontWeight: "800", color: "#5B1213" }}>Actual Customer Price: </span> ${this.state.prices.totalPrice}
        </div>
        <div >
          <span style={{ fontWeight: "800", color: "#5B1213" }}>Notary Match Service Fee: </span> ${Math.round(this.state.prices.totalPrice * 0.25 * 100) / 100}
        </div>
        <div >
          <span style={{ fontWeight: "800", color: "#5B1213" }}>You’ll Receive: </span> ${Math.round(this.state.prices.totalPrice * 0.75 * 100) / 100}
        </div>
      </Form.Item>

      <Form.Item>
        <Row>
          <Col span={12} >
            <Button 
              type="primary" icon="close" size="large" id="nm-request-tile-btn"
              onClick={this.props.onExit}
            >
              Cancel
            </Button>
          </Col>
          <Col span={12} style={{ textAlign: 'right' }} >
            <Button type="primary" icon="check-circle" size="large" htmlType="submit" className="nm-login-submit">
              Save
            </Button>
          </Col>
        </Row>
      </Form.Item>
      <Modal
        visible={this.state.showConfirmAppt}
        title={this.props.isNotSet ? "Confirm Set Appointment" : "Confirm Edit Appointment"} 
        onCancel={this.onCancel}
        footer={[
          <Button key="claimCancel" onClick={this.onCancel}>
            Cancel
          </Button>,
          <Button key="claimYes" type="primary" onClick={this.onYes}>
            Submit
          </Button>,
        ]}
      >
        {this.state.requestToConfirm != null &&
          this.confirmModalContent(this.state.requestToConfirm, this.props.isNotSet)
        }
      </Modal>
    </Form>
    );
  }

  confirmModalContent(requestToConfirm, isNotSet) {

    const { firstName, lastName, docType } = requestToConfirm;
    const { signatureCount, apptDate, apptTime, apptAddress, apptLandmark, apptNotes, additionals } = requestToConfirm.apptDetails;

    return (
      <div>
        <p> 
          <span style={{ fontSize: "medium", fontWeight: "500", color: "#5B1213" }}
          > Verify that the following information is correct </span> 
        </p>
        <p> <strong>Name:</strong> {`${firstName} ${lastName}`} </p>
        <p> <strong>Document Type:</strong> {`${docTypeDisplay(docType)}`}</p>
        <p> <strong># of Signatures:</strong> {signatureCount}</p>
        <p> <strong>Appointment Date:</strong> {apptDate}</p>
        <p> <strong>Appointment Time:</strong> {apptTime}</p>
        <p> <strong>Appointment Address:</strong> {apptAddress}</p>
        {apptLandmark &&
          <p> <strong>Appointment Landmark:</strong> {apptLandmark}</p>
        }
        {apptNotes &&
          <p> <strong>Appointment Notes:</strong> {apptNotes}</p>
        }
        {additionals.length > 0 &&
          <div> 
            <strong>Additional Services</strong> <br/>
            {
              additionals.map((a, i) => {
                return (
                  <div key={i} > ${a.price}: {a.label} </div>
                );
              })
            }
          </div>
        }

        {isNotSet &&
          <p> 
            <span style={{ fontSize: "small", fontWeight: "800", color: "#5B1213" }}> 
              Once submitted, the customer will be notified of the appointment details and will <u>be prompted 
              to pay for the appointment </u>
            </span> 
          </p>  
        }
        {!isNotSet &&
          <p> 
            <span style={{ fontSize: "small", fontWeight: "800", color: "#5B1213" }}
            > Once submitted, the customer will be notified of the appointment details. </span> 
          </p>
        }
      </div>
    );
  }

  isValidRequest(request) {
    let errMsgs = []

    const { firstName, lastName, email, docType } = request;
    const { signatureCount, apptDate, apptTime, apptAddress, additionals } = request.apptDetails;

    /* ----------  requestDetails  ---------- */
    if(firstName == null || firstName.length === 0) {
      errMsgs.push(`Please input the First Name`);
    }
    if(lastName == null || lastName.length === 0) {
      errMsgs.push(`Please input the Last Name`);
    }
    if(email == null || email.length === 0) {
      errMsgs.push(`Please input the Email Address`);
    }
    if(docType == null) {
      errMsgs.push(`Please input the Document Type`);
    }

    /* ----------  apptDetails  ---------- */
    if(isNaN(signatureCount)) {
      errMsgs.push(`Please enter the # of Signatures`);
    }
    if(apptDate == null) {
      errMsgs.push(`Please input the Appointment Date`);
    }
    if(apptTime == null) {
      errMsgs.push(`Please input the Appointment Time`);
    }

    if(apptAddress == null || apptAddress.length === 0) {
      errMsgs.push(`Please input the Appointment Address`);
    } else if(apptAddress.length < 3) {
      errMsgs.push(`Please input an Appointment Address longer than 3 characters`);
    }

    for(const add of additionals) {
      if(add.label == null || add.label.length === 0) {
        errMsgs.push(`An Additional Service label is required`);
      }
      if(add.price == null) {
        errMsgs.push(`An Additional Service price is required`);
      }
      if(add.price < 1) {
        errMsgs.push(`An Additional Service price cannot be less than $1`);
      }
    }

    if(errMsgs.length > 0) {
      /**
       * TODO
       *  use toasts instead of alerts
       *  react-toastify?
       */
      alert(errMsgs.join("\n"));
      return false;
    }

    return true;
  }

  buildAppointmentRequest(formValues) {

    const { requestId } = this.props.appt;
    const { firstName, lastName, email, landmark, notes } = formValues;
    const { apptDate, apptTime, apptEpoch, apptAddress, docType, additionals, signatureCount } = this.state;
    const { totalPrice } = this.state.prices;

    let request = {
      requestId: requestId,
      firstName: firstName,
      lastName: lastName,
      email: email,
      docType: docType,
      apptDetails: {
        signatureCount: parseInt(signatureCount, 10),
        apptDate: apptDate,
        apptTime: apptTime,
        apptEpoch: apptEpoch,
        apptAddress: apptAddress,
        customerPrice: totalPrice,
        notaryPay: Math.round(totalPrice * 0.75 * 100) / 100,
        additionals: additionals
      }
    };

    if(landmark) {
      request.apptDetails.apptLandmark = landmark;
    }
    if(notes) {
      request.apptDetails.apptNotes = notes;
    }

    // console.log(JSON.stringify(request));

    return request;
  }

  onDateChange(date, dateStr) {
    if(date === null) {
      return this.setState({
        apptDate: null
      });
    }

    const epoch = this.getEpochTime(dateStr, this.state.apptTime, date.unix());
    this.setState({
      apptDate: dateStr,
      apptEpoch: epoch
    })

  }

  onTimeChange(time, timeStr) {
    if(time === null) {
      return this.setState({
        apptTime: null
      });
    }

    const epoch = this.getEpochTime(this.state.apptDate, timeStr, time.unix());

    this.setState({
      apptTime: timeStr,
      apptEpoch: epoch
    })

  }

  getEpochTime(dateStr, timeStr, defaultEpoch) {

    if(dateStr != null && timeStr != null) {
      const timestamp = moment(`${dateStr}T${timeStr}`, 'MM-DD-YYYYTHH:mm');
      return timestamp.unix();
    } else {
      return defaultEpoch;
    }
  }
}

const AntdEditAppointmentForm = Form.create({ name: 'nm_edit_appt' })(EditAppointmentForm);

class CompleteAppointmentPanel extends Component {
  render() {

    const { appt, onExit } = this.props;

    return (
      <Row>
        <Col xs={{ span: 0 }} sm={{ span: 12 }} >
          <span style={{ fontSize: "x-large", fontWeight: "800",
                              letterSpacing: "0.2em", color: "#5B1213" }}>
            {appt.requestId}
          </span>
        </Col>
        <Col xs={{ span: 12 }} sm={{ span: 0 }} >
          <span style={{ fontSize: "large", fontWeight: "800",
                              letterSpacing: "0.1em", color: "#5B1213" }}>
            {appt.requestId}
          </span>
        </Col>
        <Col span={24} >
          <AntdCompleteAppointmentForm 
            appt={appt}
            onExit={onExit}          
          />
        </Col>
      </Row>
    );
  }
}

class CompleteAppointmentForm extends Component {

  state = {
    rating : 5,

    // ui
    showConfirmAppt: false,
    requestToConfirm: null
  }

  onRatingChange = value => {
    if (isNaN(value)) {
      return;
    }
    this.setState({
      rating: value,
    });
  };
  
  handleSubmit = e => {
    e.preventDefault();
    const values = this.props.form.getFieldsValue();
    // console.log(`values --> ${JSON.stringify(values)}`);

    let request = {
      requestId: this.props.appt.requestId,
      rating: values.rating
    };

    if(values.comments) {
      request.comments = values.comments;
    }

    // console.log(`request -> \n ${JSON.stringify(request)}`);

    this.setState({ 
      showConfirmAppt: true,
      requestToConfirm: request
    });

  };

  render() {
    const { getFieldDecorator } = this.props.form;

    return (
      <Form onSubmit={this.handleSubmit} className="appt-complete-form">
        <Form.Item>
          Customer's Rating
          <Row>
            <Col span={16}>
              {getFieldDecorator('rating', {
                initialValue: this.state.rating
              })(
                <Slider
                  min={1}
                  max={5}
                  step={1}
                  onChange={this.onRatingChange}
                />,
              )}
            </Col>
            <Col offset={2} xs={{ span: 3 }} sm={{ span: 2 }} >
              <div className="nm-appt-rating-box">
                {this.state.rating}
              </div>
            </Col>
          </Row>
        </Form.Item>

        <Form.Item>
          Comments (Optional)
          {getFieldDecorator('comments', {})(
            <Input.TextArea
              placeholder="Leave any comments on the customer and/or appointment that will be helpful for others to know"
              size="large"
            />,
          )}
        </Form.Item>

        <Form.Item>
          <Row>
            <Col span={12} >
              <Button 
                type="primary" icon="close" size="large" id="nm-request-tile-btn"
                onClick={this.props.onExit}
              >
                Cancel
              </Button>
            </Col>
            <Col xs={{ span: 0 }} sm={{ span: 12 }} style={{ textAlign: 'right' }} >
              <Button type="primary" icon="check-circle" size="large" htmlType="submit" className="nm-login-submit">
                Complete Appointment
              </Button>
            </Col>
            <Col xs={{ span: 12 }} sm={{ span: 0 }} style={{ textAlign: 'right' }} >
              <Button type="primary" icon="check-circle" htmlType="submit">
                Complete Appt
              </Button>
            </Col>
          </Row>
        </Form.Item>
        <Modal
          visible={this.state.showConfirmAppt}
          title={"Complete Appointment"} 
          onCancel={() => this.setState({ showConfirmAppt: false })}
          footer={[
            <Button key="claimCancel" onClick={() => this.setState({ showConfirmAppt: false })}>
              Cancel
            </Button>,
            <Button key="claimYes" type="primary" onClick={this.onYes.bind(this)}>
              Submit
            </Button>,
          ]}
        >
          {this.state.requestToConfirm != null &&
            this.confirmModalContent(this.state.requestToConfirm)
          }
        </Modal>
      </Form>
    );
  }

  onYes() {
    setTimeout(() => {
      this.setState({ 
        showConfirmAppt: false,
      }); 
    }, 300);

    /**
     * TODO
     * 
     * test with new completeAppointment API functionality
     */

    completeAppointment(this.state.requestToConfirm)
      .then((res) => {
        // console.log(res);
        alert(`Succesfully completed appointment ${this.props.appt.requestId}.`);
      })
      .catch((err) => {
        // console.log(err.response);
        alert(err.response.data.message);
      });
  }

  confirmModalContent(requestToConfirm) {
    const { requestId, rating, comments } = requestToConfirm;
    const { customerPrice, notaryPay } = this.props.appt.apptDetails;

    return (
      <div>
        <p> 
          <span style={{ fontSize: "medium", fontWeight: "500", color: "#5B1213" }}>
            Are you sure you want to complete this appointment
          </span> 
        </p>

        {/* the appointment was ok. i think he talked way to much. he made me late for my next appointment. next time this person should be warned that they can talk this much to the notary. other than that it was fine, i guess. he did smell a little funny tho. i wish he would have showered before i showed up. is it ok for me to ask the customer "why do you stink?". if i didn't need the money i would just left. all the talking and the bad smells were a bit much for me. but for my 1st appointment i think i did a pretty good job. */}

        <p> <strong>Request Id:</strong> {requestId} </p>
        <p> <strong>Rating:</strong> {rating} </p>
        <p style={{ paddingBottom: '0.25rem' }} > <strong>Comments:</strong> {comments || 'N/A'} </p>
        <p> <strong>Customer Payment:</strong> {`$${formatMoney(customerPrice)}`} </p>
        <p> <strong>You'll Receive:</strong> {`$${formatMoney(notaryPay)}`} </p>

        <p> 
          <span style={{ fontSize: "small", fontWeight: "800", color: "#5B1213" }}> 
            {` Once submitted, the customer will be notified about the completion of their appointment. `}
            <u>Their payment will be captured</u> and you will receive your pay in the next pay cycle.
          </span> 
        </p>  

      </div>
    );
  }

}

const AntdCompleteAppointmentForm = Form.create({ name: 'nm_complete_appt' })(CompleteAppointmentForm);


const mapStateToProps = state => {
  return {
  };
};

export default connect(mapStateToProps)(EditAppointmentPage);