import React, { Component } from 'react';
import { Row, Col, Form, Icon, Input, Button } from 'antd';
// import { loginSuccessful } from '../State/Actions/allActions'
// import { connect } from 'react-redux';
import { Auth } from 'aws-amplify';

export default class ForgotPage extends Component {
  constructor(props) {
    super(props);
    // console.log(`ForgotPage.props -> ${JSON.stringify(props)}`);
  }

  render() {
    return (
      <div className="nm-login-page">
        <Row type="flex" justify="space-around" align="middle">
          <Col>
            <ForgotPanel succesfulSigninFn={() => {
              this.props.history.push('/');
            }} />
          </Col>
        </Row>
      </div>
    );
  }
}

class ForgotPanel extends Component {

  state = {
    // used for setting new password
    email: null,

    showForm: "FORGOT", // FORGOT, NEWPASS
  }

  render() {
    return (
      <div className="nm-login-panel">
        {this.state.showForm === "FORGOT"  && 
          <Row>
            <div style={{ paddingBottom: '0.75rem' }}>
              <span style={{
                color: "#5B1213"
              }}>
                Input your email and a verification code will be emailed to you.
              </span>
            </div>
          </Row>
        }
        {this.state.showForm === "NEWPASS"  &&
          <Row>
            <div style={{ paddingBottom: '0.75rem' }}>
              <span style={{
                color: "#5B1213"
              }}>
                To reset your password, enter your new password <br/>
                and the verfication code emailed to you.
              </span>
            </div>
          </Row>
        }
        <Row type="flex" justify="space-around" align="middle">
          <Col xs={{ span: 16 }} sm={{ span: 16 }}>
            <div style={{ paddingBottom: '0.75rem' }}>
              <span style={{
                fontSize: "large", fontWeight: "800",
                color: "#5B1213"
              }}>
                Forgot Password
              </span>
            </div>
          </Col>
          <Col>
            {this.state.showForm === "FORGOT" && 
              <AntdForgotPasswordForm
                showNewPassWithEmail={(email) => {
                  this.setState({
                    showForm: "NEWPASS",
                    email: email
                  })
                }}
              />
            }
            {this.state.showForm === "NEWPASS" && 
              <AntdNewPasswordForm
                succesfulSigninFn={this.props.succesfulSigninFn}
                email={this.state.email}
              />
            }
          </Col>
        </Row>
      </div>
    );
  }
}

/**
 * 
 * ******************** ForgotPasswordForm ******************
 * 
 */

class ForgotPasswordForm extends Component {
  constructor(props) {
    super(props);
    // console.log(`ForgotPasswordForm.props -> ${JSON.stringify(props)}`);
  }

  handleSubmit = async (e) => {
    e.preventDefault();
    this.props.form.validateFields(async (err, values) => {
      if (!err) {

        const value = await this.forgotPassword(values.email);
        switch(value.res) {
          case "SUCCESS":
            this.props.showNewPassWithEmail(values.email);
            break;
          case "ERROR":
            alert(value.message);
            break;
          default:
        }
      } 
      else {
        console.error(err);
      }
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <Form onSubmit={this.handleSubmit} className="login-form">
        <Form.Item>
          {getFieldDecorator('email', {
            rules: [{ required: true, message: 'Please input your email address' }],
          })(
            <Input
              prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
              placeholder="Email"
              size="large"
            />,
          )}
        </Form.Item>
        <Form.Item>
          <div style={{ textAlign: 'center' }} >
            <Button type="primary" htmlType="submit" className="nm-login-submit">
              Submit
            </Button>
          </div>
        </Form.Item>
      </Form>
    );
  }

  forgotPassword = async (email) => {
    try {
      const loggedUser = await Auth.forgotPassword(email);

      return {
        res: "SUCCESS"
      };

    } catch (err) {
      if (err.code === 'UserNotConfirmedException') {
          // The error happens if the user didn't finish the confirmation step when signing up
          // In this case you need to resend the code and confirm the user
          // About how to resend the code and confirm the user, please check the signUp part
          console.log(err);
      } else if (err.code === 'PasswordResetRequiredException') {
          // The error happens when the password is reset in the Cognito console
          // In this case you need to call forgotPassword to reset the password
          // Please check the Forgot Password part.
          console.log(err);
      } else if (err.code === 'NotAuthorizedException') {
          // The error happens when the incorrect password is provided
          console.log(err);
      } else if (err.code === 'UserNotFoundException') {
          // The error happens when the supplied username/email does not exist in the Cognito user pool
          console.log(err);
      } else {
          console.log(err);
      }
      return {
        res: "ERROR",
        message: err.message
      };
    }
  }
}

const AntdForgotPasswordForm = Form.create({ name: 'nm_forgotpass' })(ForgotPasswordForm);

/**
 * 
 * ******************** NewPasswordForm ******************
 * 
 */

class NewPasswordForm extends Component {
  constructor(props) {
    super(props);
    console.log(`NewPasswordForm.props -> ${JSON.stringify(props)}`);
  }

  handleSubmit = async (e) => {
    e.preventDefault();
    this.props.form.validateFields(async (err, values) => {
      if (!err) {

        if(values.password !== values.passwordConfirm) {
          alert("New Password and New Password Confirm are not equal.");
          return;
        }

        const value = await this.forgotPasswordSubmit(this.props.email, values.verificationCode, values.password);
        switch(value.res) {
          case "SUCCESS":
            this.props.succesfulSigninFn();
            setTimeout(() => alert(`Your password has been updated. Feel free to login now!`), 300);
            break;
          case "ERROR":
            alert(value.message);
            break;
          default:
        }
      }
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <Form onSubmit={this.handleSubmit} className="login-form">
        <Form.Item>
          {getFieldDecorator('verificationCode', {
            rules: [{ required: true, message: 'Please input verificationCode' }],
          })(
            <Input
              prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
              placeholder="Verification Code"
              size="large"
            />,
          )}
        </Form.Item>
        <Form.Item>
          {getFieldDecorator('password', {
            rules: [{ required: true, message: 'Please input your New Password' }],
          })(
            <Input
              prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
              type="password"
              placeholder="New Password"
              size="large"
            />,
          )}
        </Form.Item>
        <Form.Item>
          {getFieldDecorator('passwordConfirm', {
            rules: [{ required: true, message: 'Please confirm your New Password' }],
          })(
            <Input
              prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
              type="password"
              placeholder="New Password Confirm"
              size="large"
            />,
          )}
        </Form.Item>
        <Form.Item>
          <span style={{ color: "#5B1213" }}>
            Password must fulfill the following:
          </span>
          <ul style={{ color: "#5B1213", lineHeight: "85%" }}>
            <li>Must be 8 characters long </li>
            <li>Contain a lowercase letter</li>
            <li>Contain a uppercase letter</li>
            <li>Contain a symbol character</li>
          </ul>
        </Form.Item>
        <Form.Item>
          <div style={{ textAlign: 'center' }} >
            <Button type="primary" htmlType="submit" className="nm-login-submit">
              Reset Password
            </Button>
          </div>
        </Form.Item>
      </Form>
    );
  }

  forgotPasswordSubmit = async (email, verificationCode, newPassword) => {
    try {
      await Auth.forgotPasswordSubmit(email, verificationCode, newPassword);

      return {
        res: "SUCCESS"
      };

    } catch (err) {
      console.log(err);
      return {
        res: "ERROR",
        message: err.message
      };
    }
  }
}

const AntdNewPasswordForm = Form.create({ name: 'nm_forgot_newpass' })(NewPasswordForm);

