import React, { Component } from 'react';
import { Row, Col, Button, Checkbox } from 'antd';
import { docTypeDisplay, timeToEnglish } from '../../Util/display'
import moment from 'moment'

export class ApptTile extends Component {
	state = {
    visible: false
  }

  render() {

    const { appt, isComplete } = this.props;
    const { apptDetails, paid } = appt;

    let apptEpoch, apptAddress, apptLandmark;
    if(apptDetails) {
      apptEpoch = apptDetails.apptEpoch;
      apptAddress = apptDetails.apptAddress;
      apptLandmark = apptDetails.apptLandmark;
    }

    const isClaimed = appt.apptStatus === 'CLAIMED';

    return (
      <div className="nm-appt-tile-container">
        <Row>
          <Col xs={{ span: 0 }} sm={{ span: 12 }}>
            <div>
              <span style={{ fontSize: "large", fontWeight: "800",
                              letterSpacing: "0.2em", color: "#5B1213" }}>
                {appt.requestId}
              </span>
            </div>
          </Col>
          <Col xs={{ span: 12 }} sm={{ span: 0 }}>
            <div>
              <span style={{ fontSize: "large", fontWeight: "800",
                              letterSpacing: "0.1em", color: "#5B1213" }}>
                {appt.requestId}
              </span>
            </div>
          </Col>

          {/* conditionally show the button text as 'Set Appointment' or 'Set Appt' based on screen size  */}
          <Col xs={{ span: 0 }} sm={{ span: 12 }} style={{ textAlign: 'right' }} >
            <Button 
              type="primary" shape="round" icon="user" size="large" id="nm-appt-tile-btn" disabled={isComplete}
              onClick={() => {
                if(isComplete) return;
                this.props.editApptFn(isClaimed, appt)
              }}
            >
              {isComplete ? 'Complete' : isClaimed ? 'Set Appointment' : 'Edit Appointment'}
            </Button>
          </Col>
          <Col xs={{ span: 12 }} sm={{ span: 0 }} style={{ textAlign: 'right' }} >
            <Button 
              type="primary" shape="round" icon="user" size="large" id="nm-appt-tile-btn" disabled={isComplete}
              onClick={() => {
                if(isComplete) return;
                this.props.editApptFn(isClaimed, appt)
              }}
            >
              {isComplete ? 'Complete' : isClaimed ? 'Set Appt' : 'Edit Appt'}
            </Button>
          </Col>

        </Row>
        <Row style={{ paddingTop: '1rem', paddingBottom: '1rem' }}>
          <Col span={12} style={{ textAlign: 'center' }}>
            <div>
              {`${appt.firstName} ${appt.lastName}`}
            </div>
            <div>
              <a 
                href={`tel:${appt.phoneNo}`}
                style={{ textDecoration: 'underline' }}
              >
                {appt.phoneNo}
              </a>
            </div>
            {/* <div>
              123 Testing Ave.
            </div>
            <div>
              Seattle, WA
            </div>
            <div>
              98101
            </div> */}
          </Col>
          <Col span={12} style={{ textAlign: 'center' }}>
            <div>
              <span style={{ fontSize: "large", fontWeight: "500",
                              letterSpacing: "0.05em", color: "#5B1213" }}>
                {docTypeDisplay(appt.docType)}
              </span>
            </div>
            {/* <div>
              1 - # of Copies
            </div>
            <div>
              <Checkbox disabled={true} checked={true}/> Has Physical Copy
            </div>
            <div>
              <Checkbox disabled={true} checked={false}/> Needs Delivery
            </div> */}
          </Col>
        </Row>
        <Row type="flex" justify="center" >
          <div>
            Appointment Date & Time: <strong> {apptEpoch ? moment.unix(apptEpoch).format('MM-DD-YYYY, h:mm a') : 'N/A'} </strong>
          </div>
        </Row>
        <Row type="flex" justify="center" >
          <div>
            <span> Appointment Address: </span>   
        
            {apptAddress 
            ?  (
              <a 
                target="_blank"
                rel="noopener noreferrer"
                href={`http://maps.google.com/?q=${apptAddress}`}
                style={{ textDecoration: 'underline' }}
              >
                {apptAddress}
              </a>
            )
            : 'N/A'}

          </div>
        </Row>
        <Row type="flex" justify="center" >
          <div>
            Appointment Landmark: {apptLandmark ? apptLandmark : 'N/A'}
          </div>
        </Row>
        <Row type="flex" justify="center" >
          <div>
            Customer Paid: <strong> {paid ? 'Yes' : 'No'} </strong>
          </div>
        </Row>
        {/* <Row type="flex" justify="center" >
          <div>
            Quoted Customer Price: <strong> N/A </strong>
          </div>
        </Row>
        <Row type="flex" justify="center" >
          <div>
            Actual Customer Price: <strong> N/A </strong>
          </div>
        </Row> */}
      </div>
    );
  }
}
